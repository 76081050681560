<template>
  <div class="d-flex flex-column">
    <div class="d-flex">
      <!-- <button type="button" class="btn notification-btn">
        <img src="/assets/images/new-icon/notification.svg" alt="notification" width="20" height="20" />
      </button> -->
      <div class="profile-signout-dropdown-outer">
        <div class="dropdown">
          <button class="
              btn btn-secondary
              dropdown-toggle
              switch-branch-btn
              text-truncate
              d-inline-block
            " type="button" id="switchdealerbranch" data-bs-toggle="dropdown" aria-expanded="false">
            <div class="d-flex align-items-center">
              <div class="flex-shrink-0">
                <img v-if="this.user_singal_image == null || this.user_singal_image == ''" src="/assets/images/profile.svg"
                  class="profile-header-avatar rounded-circle" alt="" width="20" height="20" />
                <img v-if="this.user_singal_image != null && this.user_singal_image != ''" :src="'https://storage.googleapis.com/' + this.localstorageimgpath + '/profile_image/' + this.user_singal_image" class="profile-header-avatar rounded-circle" alt=""
                  width="20" height="20" />
              </div>
              <div class="flex-grow-1 ms-2 text-capitalize ellipsis-user-name"> {{ user_name }} </div>
            </div>
          </button>
          <ul class="dropdown-menu" aria-labelledby="switchdealerbranch">
            <li>
              <router-link class="dropdown-item" to="/myprofile"><img src="/assets/images/new-icon/icon-profile-edit.svg" class="profile-menu-icon"
                  alt="my-profile" /><span>My Profile</span></router-link>
            </li>
            <li>
              <span class="dropdown-item cursor-pointer" @click="logout"><img src="/assets/images/new-icon/icon-profile-signout.svg" class="profile-menu-icon"
                  alt="sign-out" /><span>Sign Out</span></span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import commonMixin from "../mixins/commonMixin";
import ApiService from "../service/ApiService";
import { useToast } from 'vue-toast-notification';
import useValidate from "@vuelidate/core";

export default {
  mixins: [ commonMixin ],
  data () {
    const toast = useToast();
    return {
      v$: useValidate(),
      toast: toast,
      user_name: "",
      user_image: "",
      localstorageimgpath: '',
      user_singal_image: "",
    };
  },

  ApiService: null,
  created () {
    this.ApiService = new ApiService();
  },
  mounted () {
    var localdata = this.$store.state.loggedInUserSessions.user;
    this.user_name = localdata.aa8;
    this.localstorageimgpath = this.$store.state.loggedInUserSessions.localstoragepath;
    this.user_singal_image = localdata.aa14;
  },
  methods: {
    logout () {
      this.ApiService.logout().then( ( data ) => {
        if ( data.status == 200 ) {
          var successMsg = data.message;
          this.toast.open( {
            message: successMsg,
            type: "success",
            duration: 3000,
            position: "top-right",
          } );
          setTimeout( () => {
            this.v$.$reset();
          }, 0 );
        } else {
          var errorMsg = data.message;
          this.toast.open( {
            message: errorMsg,
            type: "error",
            duration: 3000,
            position: "top-right",
          } );
        }
      } ).catch( ( error ) => {
        console.log( error )
      } )
    },
  },
};
</script>
<style scoped>
.ellipsis-user-name {
  white-space: nowrap;
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.header-host-name {
  font-family: 'OpenSans-Regular';
  font-size: 16px;
  text-align: left;
  font-weight: 600;
  line-height: 18px;
  color: #0F0F0F;
  letter-spacing: 0.66px;
  text-transform: capitalize;
}
</style>