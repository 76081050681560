import { reactive } from 'vue'
import moment from 'moment';
const CryptoJS = require( 'crypto-js' );
export default function commonFunctions () {
  const timerState = reactive( {
    timerMobileStatus: false,
    timerMobileCount: 120,
    timerMobileInterval: 0,
    resendOtpBtnStatus: false
  } )

  const onlyNumber = ( $event ) => {
    let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    if ( keyCode < 48 || keyCode > 57 ) {
      $event.preventDefault();
    }
  }

  const isLetterWithSpace = ( e ) => {
    let value = e.target.value
    let count = countWhiteSpace( e.target.value )
    if ( count <= 2 ) {
      let charspace = String.fromCharCode( e.keyCode );
      if ( charspace == ' ' && ( value == null || value == '' ) ) {
        e.preventDefault();
      } else if ( /^[A-Za-z\s]+$/gm.test( charspace ) ) {
        return true
      } else { e.preventDefault() }
    } else {
      e.preventDefault()
    }
  }

  function countWhiteSpace ( str ) {
    let gaps = 0;
    const isWhitespace = ch => ' \t\n\r\v'.indexOf( ch ) > -1;
    for ( let i = 0; i < str.length; i++ )
      if ( isWhitespace( str[ i ] ) && !isWhitespace( str[ i - 1 ] ) )
        ++gaps;
    return gaps;
  }

  const isLetterWithOutSpace = ( e ) => {
    let char = String.fromCharCode( e.keyCode );
    if ( /^[A-Za-z]+$/.test( char ) ) return true;
    else e.preventDefault();
  }

  const mobileSearchFormat = ( $event ) => {
    let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    if ( keyCode < 48 || keyCode > 57 ) {
      $event.preventDefault();
    }
    var mobileInput = document.getElementById( "searchmobInput" );
    if (
      ( mobileInput.value == "" || mobileInput.length > 1 ) &&
      ( keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52 )
    ) {
      $event.preventDefault();
    }
  }

  const onlymobileEditFormat = ( $event ) => {
    let keyCode = $event.keyCode ? $event.keyCode : $event.which;
    if ( keyCode < 48 || keyCode > 57 ) {
      $event.preventDefault();
    }

    var ownermobileInput = document.getElementById( "ownermobileInput" );
    if (
      ( ownermobileInput.value == "" || ownermobileInput.length > 1 ) &&
      ( keyCode == 48 || keyCode == 49 || keyCode == 50 || keyCode == 51 || keyCode == 52 || keyCode == 53 )
    ) {
      $event.preventDefault();
    }
  }

  const checkEmpty = ( field ) => ( !!( ( field !== undefined && field !== null && field !== '' ) ) );

  const startMobileTimer = () => {
    timerState.timerMobileStatus = true;
    if ( timerState.timerMobileCount > 0 ) {
      setTimeout( () => {
        timerState.timerMobileCount--;
        startMobileTimer();
      }, 1000 );
      const minutes = Math.floor( timerState.timerMobileCount / 60 );
      let seconds = timerState.timerMobileCount % 60;
      if ( seconds < 10 ) {
        timerState.timerMobileInterval = `0${ minutes }:0${ seconds }`;
      } else {
        timerState.timerMobileInterval = `0${ minutes }:${ seconds }`;
      }
      timerState.resendOtpBtnStatus = false;
    } else {
      timerState.timerMobileCount = 120;
      timerState.timerMobileStatus = false;
      timerState.resendOtpBtnStatus = true;
    }
  }

  const validateAddGstNumber = ( e ) => {
    //var regexGst = /[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    var regexGst = /[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    let add_gst_number = e.target.value.toUpperCase();
    if ( add_gst_number && !add_gst_number.match( regexGst ) ) {
      let gstErrMsg = "Please enter valid GST Number";
      let errStatus = true;
      let pannumber = null;
      return { gstErrMsg, errStatus, pannumber }
    } else {
      let gstErrMsg = "";
      let errStatus = false;
      let pannumber = add_gst_number.substr( 2, 10 );
      return { gstErrMsg, errStatus, pannumber }
    }
  }

  const validateAddPanCardNumber = ( e ) => {
    var regex = /([A-Z]){5}([0-9]){4}([A-Z]){1}$/;
    let add_pan_number = e.target.value.toUpperCase();
    if ( add_pan_number && !add_pan_number.match( regex ) ) {
      let panErrMsg = "Please enter valid PAN Number";
      let errStatus = true;
      return { panErrMsg, errStatus }
    } else {
      let panErrMsg = "";
      let errStatus = false;
      return { panErrMsg, errStatus }
    }
  }

  const encrypt = ( text ) => {
    return CryptoJS.enc.Base64.stringify( CryptoJS.enc.Utf8.parse( text ) );
  };

  const decrypt = ( data ) => {
    return CryptoJS.enc.Base64.parse( data ).toString( CryptoJS.enc.Utf8 );
  };

  const isLetterWithMultipleSpace = ( e ) => {
    let charspace = String.fromCharCode( e.keyCode );
    if ( /^[A-Za-z\s]+$/.test( charspace ) ) return true;
    else e.preventDefault();
  }

  const maskedNumber = ( getvalue ) => {
    const maskedDigits = "X".repeat( getvalue.length - 6 );
    const FirstThreeDigits = getvalue.slice( 0, 3 );
    const lastThreeDigits = getvalue.slice( -3 );
    return FirstThreeDigits + maskedDigits + lastThreeDigits;
  }

  const maskedEmail = ( getvalue ) => {
    const [ name, domain ] = getvalue.split( "@" );
    return `${ name[ 0 ] }${ new Array( name.length ).join( "*" ) }@${ domain }`;
  }

  const format_datetime = ( value ) => {
    if ( value ) {
      return moment( String( value ) ).format( "DD/MM/YYYY hh:mm a" );
    } else {
      return 'N/A';
    }
  }

  const format_date = ( value ) => {
    if ( value ) {
      return moment( String( value ) ).format( "DD/MM/YYYY" );
    } else {
      return 'N/A';
    }
  }

  const format_timeonly = ( value ) => {
    if ( value ) {
      return moment( String( value ) ).format( "hh:mm:ss a" );
    } else {
      return 'N/A';
    }
  }
  const format_timestamp = ( value ) => {
    if ( value ) {
      var localTime = new Date( value * 1000 ).toUTCString();
      return moment( localTime ).format( "DD/MM/YYYY @ hh:mm a" );
    } else {
      return 'N/A';
    }
  }

  const format_dateonlystamp = ( value ) => {
    if ( value ) {
      var localTime = new Date( value * 1000 ).toUTCString();
      return moment( localTime ).format( "DD/MM/YYYY" );
    } else {
      return 'N/A';
    }
  }

  const format_datemonthstamp = ( value ) => {
    if ( value ) {
      var localTime = new Date( value * 1000 ).toUTCString();
      return moment( localTime ).format( "DD MMM,YYYY" );
    } else {
      return 'N/A';
    }
  }

  const format_timeonlystamp = ( value ) => {
    if ( value ) {
      var localTime = new Date( value * 1000 ).toUTCString();
      return moment( localTime ).format( "hh:mm:ss a" );
    } else {
      return 'N/A';
    }
  }

  const disableAutocomplete = () => {
    const usernameInput = document.getElementById( "ownermobileInput" )
    const passwordInput = document.getElementById( 'password' );
    if ( checkEmpty( usernameInput ) ) {
      usernameInput.setAttribute( 'autocomplete', 'off' );
    }
    if ( checkEmpty( passwordInput ) ) {
      passwordInput.setAttribute( 'autocomplete', 'new-password' );
    }
  }

  return {
    onlyNumber, isLetterWithSpace, isLetterWithOutSpace, mobileSearchFormat, onlymobileEditFormat, checkEmpty, startMobileTimer,
    timerState, validateAddGstNumber, validateAddPanCardNumber, encrypt, decrypt, isLetterWithMultipleSpace, maskedNumber,
    maskedEmail, format_datetime, format_date, format_timeonly, format_timestamp, format_dateonlystamp, format_datemonthstamp,
    format_timeonlystamp, disableAutocomplete
  }
}