import { reactive } from "vue";

const state = reactive( {
    steppers: 1,
    arealoaderflag: false,
    hostId: null,
    hostuserFk: '',
    hostuserpassword: '',
} );

const newHostRegState = reactive( {
    hostId: null,
    hostuserFk: '',
    hostuserpassword: '',
    newHostDetails: null,
    previousBranchCount: 1,
    newHostAllDetails: null,
    primobile: '',
    fullname: '',
    gstcompanydetails: null,
    gstVerifiedStatus: false,
    businesstype: [],
    maplink: '',
    maplinktemp: '',
    designation: null,
    multiBranchOfOperations: null,
    headOfficeOrMainPlaceOfOperations: null,
    latitude: "",
    longitude: "",
    mainPageLoader: false
} );

const localimagepath = null;
const loginusername = null;

export default { state, newHostRegState, localimagepath, loginusername };