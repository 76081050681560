<template>
  <!-- @click="checkSessionTimeout" -->
  <div class="container-fluid" v-if="$route.meta.requiresAuth" >
    
    <div class="row application-bg">
      <SidebarTabs />
      <main class="col-md-9 col-lg-9 ms-sm-auto layout-content-outer">
        <div class="layout-content-inner">
          <router-view />
        </div>
      </main>
    </div>
    <!-- End for Police side tabs -->
  </div>
  <div class="container-fluid" v-if="!$route.meta.requiresAuth">
    <router-view />
  </div>
  <div class="modal-mask" v-if="showReloginPopup">
    <div class="
        modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered
        custom-modal-outer
      ">
      <div class="modal-content modal-outer" v-if="showReloginPopup">
        <div class="row g-2">
          <div class="col-lg-7 col-md-7 col-12">
            <div class="text-center custom-img-outer">
              <img src="/assets/images/goaelec-background.png" alt="" width="100%" height="100%" style="border-radius: 7px 0px 0px 7px;" />
            </div>
          </div>
          <div class="col-lg-5 col-md-5 col-12">
            <div class="p-3 border custom-card-outer h-100">
              <div class="text-center">
                <div class="mb-3">
                  <img src="/assets/images/profile.svg" class="text-center rounded-circle" alt="" width="50" height="50" v-if="userData.ad19 == null && userData.ad62 == null" />
                  <img :src="userData.ad19" class="text-center rounded-circle" alt="" width="50" height="50" v-if="userData.ad19 != null" />
                  <img :src="userData.ad61" class="text-center rounded-circle" alt="" width="50" height="50" v-if="userData.ad61 != null" />
                </div>
                <div class="mb-3 custom-user-name text-capitalize"> {{ userfullname }} </div>
                <div class="horizontal-line mb-3"></div>
                <div class="mb-4 custom-user-content">
                  <div>It's been 60 minutes you were away from</div>
                  <div>Visitor Connect</div>
                  <div v-if="userType != 3">Kindly Re-enter your password to Resume</div>
                  <div v-if="userType == 3">Kindly Re-login using OTP to Resume</div>
                </div>
                <div class="col-12" v-if="userType != 3">
                  <div class="custom-form-group">
                    <div class="input-group">
                      <input class="form-control custom-input-passward" placeholder="Enter Password" aria-label="Password" v-model="password"
                        :type="passwordVisibility" maxlength="25" aria-describedby="formPasswordInput" autocomplete="off" tabindex="0" />
                      <span class="input-group-text" id="formPasswordInput">
                        <button @click="showPasswordType()" v-if="passwordVisibility == 'password'" class="btn btn-link login-password-type">
                          <i class="pi pi-eye-slash"></i>
                        </button>
                        <button @click="hidePasswordType()" v-if="passwordVisibility == 'text'" class="btn btn-link login-password-type">
                          <i class="pi pi-eye"></i>
                        </button>
                      </span>
                    </div>
                    <div class="custom-error" v-if="v$.password.$error"> {{ v$.password.$errors[0].$message }} </div>
                  </div>
                </div>
                <div class="row mt-3" v-if="userType != 3">
                  <div class="col-lg-12 col-md-12 col-12">
                    <button type="button" class="btn custom-form-group-btn w-100" @click="login()" :disabled="showloader" tabindex="1">
                      <span v-if="!showloader">Submit</span>
                      <div class="spinner-border text-light custom-spinner-loader-btn" v-if="showloader"></div>
                    </button>
                  </div>
                </div>
                <div class="row mt-3" v-if="userType == 3 && !lawuserotpstatus">
                  <div class="col-lg-12 col-md-12 col-12">
                    <button type="button" class="btn custom-form-group-btn w-100" @click="getOtpForLawUserLogin()" :disabled="showloader" tabindex="1">
                      <span v-if="!showloader">Get OTP</span>
                      <div class="spinner-border text-light custom-spinner-loader-btn" v-if="showloader"></div>
                    </button>
                  </div>
                </div>
                <div class="row" v-if="userType == 3 && lawuserotpstatus">
                  <div class="col-lg-12 col-md-12 col-12">
                    <div class="custom-form-group">
                      <div class="input-group">
                        <input class="form-control" placeholder="Enter OTP" aria-label="Enter OTP" v-model="lauuserotp" maxlength="6"
                          aria-describedby="formPasswordInput" autocomplete="off" tabindex="0" />
                      </div>
                      <div class="custom-error" v-if="errormsg"> {{ errormsg }} </div>
                      <div class="custom-error" v-if="v$.lauuserotp.$error"> {{ v$.lauuserotp.$errors[0].$message }} </div>
                      <div class="forgot-password-group text-end mt-1" v-if="!timerState.timerMobileStatus">
                      <button class="btn resend-otp-btn" @click="getOtpForLawUserLogin()" type="submit" tabindex="3">
                        <span v-if="!showloader" class="login">Resend OTP</span>
                        <div class="spinner-border text-light custom-spinner-loader-btn" role="status" v-if="showloader">
                        </div>
                      </button>
                    </div>
                    <div class="forgot-password-group text-end mt-1" v-if="timerState.timerMobileStatus">
                      <span class="custom-error">Wait for Resend - {{ timerState.timerMobileInterval }} </span>
                    </div>
                    </div>
                  </div>
                </div>
                <div class="row" v-if="userType == 3 && lawuserotpstatus">
                  <div class="col-lg-12 col-md-12 col-12">
                    <button type="button" class="btn custom-form-group-btn w-100" @click="verifyAndLoginForLawUser()" :disabled="showloader" tabindex="1">
                      <span v-if="!showloader">Verify & Login</span>
                      <div class="spinner-border text-light custom-spinner-loader-btn" v-if="showloader"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SidebarTabs from "./components/SidebarTabs.vue";
import moment from "moment";
import ApiService from "./service/ApiService";
import { required, helpers, maxLength, minLength } from "@vuelidate/validators";
import useValidate from "@vuelidate/core";
import { useToast } from 'vue-toast-notification';
import commonMixin from "./mixins/commonMixin";
export default {
  mixins: [ commonMixin ],
  components: {
    SidebarTabs: SidebarTabs,
  },
  data () {
    const toast = useToast();
    return {
      toast: toast,
      v$: useValidate(),
      passwordVisibility: "password",
      showloader: false,
      showReloginPopup: false,
      username: "",
      password: "",
      userfullname: "",
      userType: "",
      userData: "",
      userId: "",
      errormsg: "",
      lauuserotp: "",
      lawuserotpstatus: false,
      timerState: {
        timerMobileStatus: false,
        timerMobileCount: 120,
        timerMobileInterval: 0,
        resendOtpBtnStatus: false
      }
    };
  },
  mounted () {
    if (
      localStorage.error_toaster_display &&
      localStorage.error_toaster_display == 1
    ) {
      this.toast.open( {
        message: localStorage.logout_reason,
        type: "error",
        duration: 3000,
        position: "top-right",
      } );
      localStorage.clear();
    }
  },
  ApiService: null,
  created () {
    this.ApiService = new ApiService();
  },

  computed: {
    updateUserType () {
      return this.$store.state.loggedInUserSessions.user
    },
    // checkAlreadyLoggedInUser () {
    //   return this.$store.state.loggedInUserSessions.user_reference
    // }
  },
  watch: {
    password () {
      this.v$.$validate();
    },
    updateUserType ( newValue ) {
      var localdata = newValue;
      this.userData = newValue;
      this.userType = localdata.ad23
    },
    // checkAlreadyLoggedInUser () {
    //   if ( this.$store.state.loggedInUserSessions.user_reference ) {
    //     this.$router.push('/dashboard');
    //   }
    // }
  },

  validations () {
    return {
      password: {
        required: helpers.withMessage( "Please enter password", required ),
        maxLength: helpers.withMessage(
          "Password should be max 25 length",
          maxLength( 25 )
        ),
        minLength: helpers.withMessage(
          "Password should be min 6 length",
          minLength( 6 )
        ),
      },
      lauuserotp: {
        required: helpers.withMessage( "Please enter otp", required ),
      }
    };
  },

  methods: {
    showPasswordType () {
      this.passwordVisibility = "text";
    },

    hidePasswordType () {
      this.passwordVisibility = "password";
    },

    checkSessionTimeout () {
      if (
        localStorage.last_login_time_deep_search &&
        localStorage.last_login_time_deep_search != null
      ) {
        let now = new Date().toUTCString();
        var diff =
          ( moment.utc( now ) - moment.utc( this.decrypt( localStorage.last_login_time_deep_search ) ) ) / 1000;
        diff /= 60;
        diff = Math.abs( Math.round( diff ) );
        if ( diff >= 60 ) {
          this.toast.open( {
            message:
              "Your session expired as you were inactive for more than 60 minutes. Please login again to use ",
            type: "error",
            duration: 3000,
            position: "top-right",
          } );
          setTimeout( () => {
            this.logoutOnSessionTimeOut();
          }, 1000 );
        } else {
          var currentTime = new Date().toUTCString();
          localStorage[ "last_login_time_deep_search" ] = this.encrypt( currentTime );
        }
      }
    },
    logoutOnSessionTimeOut () {
      this.ApiService.logoutOnSessionTimeOut( { logout_reason: 3 } ).then(
        ( data ) => {
          if ( data.status == 200 ) {
            this.userId = this.userData.ad1;
            this.username = this.userData.ad15;
            this.userfullname = this.userData.ad9;
            this.getClientByDomain()
            this.showReloginPopup = true;
          }
        }
      ).catch( ( error ) => {
        console.log( error )
      } )
    },
    getClientByDomain () {
      this.ApiService.getClientByDomain().then( ( data ) => {
        if ( data.success === true ) {
          var client = data.data.maa1;
          let encryptClient = this.encrypt( client )
          localStorage[ 'client_deep_search' ] = encryptClient;
        }
      } ).catch( ( $error ) => {
        console.log( "error", $error )
      } )
    },
    login () {
      this.v$.$validate();
      let fields = {};
      fields[ "login_id" ] = this.username;
      fields[ "login_password" ] = this.password;
      fields[ "user_id" ] = this.userId;
      if ( !this.v$.password.$error ) {
        this.showloader = true;
        this.ApiService.login( fields ).then( ( data ) => {
          if ( data.status === 200 ) {
            this.showReloginPopup = false;
            this.showloader = false;
            var currentTime = new Date().toUTCString();
            let encryptDate = this.encrypt( currentTime )
            localStorage[ 'last_login_time_deep_search' ] = encryptDate;
            var user = data.data.user_data.ad1;
            let encryptUser = this.encrypt( user )
            localStorage[ 'user_deep_search' ] = encryptUser;
            location.reload();
            setTimeout( () => {
              this.v$.$reset();
            }, 0 );
          } else {
            this.showloader = false;
            this.toast.open( {
              message: data.message,
              type: "error",
              duration: 3000,
              position: "top-right",
            } );
          }
        } ).catch( ( error ) => {
          console.log( error )
        } )
      }
    },

    getOtpForLawUserLogin () {
      this.showloader = true;
      this.ApiService.generateLawOtp( { login_id: this.username } ).then(
        ( data ) => {
          if ( data.status == 200 ) {
            this.showloader = false;
            this.errormsg = "";
            this.lawuserotpstatus = true;
            this.timerState.timerMobileStatus = false;
            this.timerState.timerMobileCount = 120;
            this.timerState.timerMobileInterval = 0;
            this.startMobileTimer();
          } else {
            this.showloader = false;
            this.errormsg = data.message;
            this.lawuserotpstatus = false;
          }
        }
      ).catch( ( $error ) => {
        console.log( "error", $error )
      } )
    },

    verifyAndLoginForLawUser () {
      this.v$.$validate();
      let fields = {};
      fields[ "login_id" ] = this.username;
      fields[ "otp" ] = this.lauuserotp;
      if ( !this.v$.lauuserotp.$error ) {
        this.showloader = true;
        this.ApiService.verifyLawOtp( fields ).then(
          ( data ) => {
            if ( data.success === true ) {
              this.showloader = false;
              var currentTime = new Date().toUTCString();
              let encryptDate = this.encrypt( currentTime )
              localStorage[ 'last_login_time_deep_search' ] = encryptDate;
              window.location.href = "/dashboard";
              var user = data.data.user_data.ad1;
              let encryptUser = this.encrypt( user )
              localStorage[ 'user_deep_search' ] = encryptUser;
            } else {
              this.showloader = false;
              this.errormsg = data.message;
              this.timerState.timerMobileInterval = null;
              this.timerState.timerMobileStatus = false;
              this.timerState.timerMobileCount = 0;
            }
          }
        ).catch( ( $error ) => {
          console.log( "error", $error )
        } )
      }
    },

    startMobileTimer () {
      this.timerState.timerMobileStatus = true;
      if ( this.timerState.timerMobileCount > 0 ) {
        setTimeout( () => {
          this.timerState.timerMobileCount--;
          this.startMobileTimer();
        }, 1000 );
        const minutes = Math.floor( this.timerState.timerMobileCount / 60 );
        let seconds = this.timerState.timerMobileCount % 60;
        if ( seconds < 10 ) {
          this.timerState.timerMobileInterval = `0${ minutes }:0${ seconds }`;
        } else {
          this.timerState.timerMobileInterval = `0${ minutes }:${ seconds }`;
        }
        this.timerState.resendOtpBtnStatus = false;
      } else {
        this.timerState.timerMobileCount = 120;
        this.timerState.timerMobileStatus = false;
        this.timerState.resendOtpBtnStatus = true;
      }
    }
  },
};
</script>
<style scoped>
.modal-content.modal-outer {
  padding: 10px;
}

.horizontal-line {
  border: 1px solid #ebeef5;
  opacity: 1;
}

.custom-user-name {
  letter-spacing: 0.32px;
  color: #000000;
  opacity: 1;
  font-size: 20px;
  font-family: 'OpenSans-Regular';
}

.custom-user-content {
  letter-spacing: 0.26px;
  color: #000000;
  opacity: 1;
  font-size: 14px;
  font-family: 'OpenSans-Regular';
}

.modal-dialog-scrollable .modal-content {
  max-height: 430px;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: #e9e9e4;
}

.modal-dialog-scrollable .modal-content::-webkit-scrollbar {
  width: 0.3em;
  height: 0.3em;
}

.modal-dialog-scrollable .modal-content::-webkit-scrollbar-thumb {
  background-color: rgb(113 115 119 / 49%);
  border-radius: 3px;
}

.custom-form-group {
  margin-bottom: 40px;
}

.custom-logo {
  background: #2a2a45 0% 0% no-repeat padding-box;
  border-radius: 4px;
  color: #000000;
  padding: 12px;
  text-align: center;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  height: 50px;
}

.modal-step-next-btns.btn:focus,
.modal-step-next-btns.btn:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
}

.custom-img-outer {
  height: 100%;
  background-color: #ababab;
}

.custom-card-outer {
  background-color: #ffffff;
}
</style>